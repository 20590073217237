<template>
  <b-form-group
    label="Iban No"
    label-for="iban"
  >
    <validation-provider
      #default="{ errors }"
      name="Iban No"
      rules="required"
    >
      <cleave
        id="iban"
        v-model="dataItem.iban"
        class="form-control"
        :raw="true"
        :options="options.iban"
        placeholder="Iban"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
// TR76 0009 9012 3456 7800 1000 01
export default {
  name: 'Iban',
  components: {
    BFormGroup,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      required,
      options: {
        iban: {
          prefix: 'TR',
          blocks: [2, 2, 4, 4, 4, 4, 4, 2],
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['bankAccounts/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
