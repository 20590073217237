<template>
  <b-form-group
    label="Swift Kodu"
    label-for="swift_code"
  >
    <b-form-input
      id="swift_code"
      v-model="dataItem.swift_code"
      placeholder="Swift Kodu"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'SwiftCode',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['bankAccounts/dataItem']
    },
  },
}
</script>
