<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <banks />
      </b-col>
      <b-col cols="12">
        <title-name />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <branch-code />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <branch />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <account-code />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <swift-code />
      </b-col>
      <b-col
        cols="12"
      >
        <iban />
      </b-col>
      <b-col
        cols="12"
      >
        <currencies />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import Banks from '@/views/Admin/Defines/Bank_accounts/elements/Banks.vue'
import TitleName from '@/views/Admin/Defines/Bank_accounts/elements/Title.vue'
import BranchCode from '@/views/Admin/Defines/Bank_accounts/elements/Branch_code.vue'
import Branch from '@/views/Admin/Defines/Bank_accounts/elements/Branch.vue'
import AccountCode from '@/views/Admin/Defines/Bank_accounts/elements/Account_code.vue'
import SwiftCode from '@/views/Admin/Defines/Bank_accounts/elements/Swift_code.vue'
import Iban from '@/views/Admin/Defines/Bank_accounts/elements/Iban.vue'
import Currencies from '@/views/Admin/Defines/Bank_accounts/elements/Currencies.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    Banks,
    TitleName,
    BranchCode,
    Branch,
    AccountCode,
    SwiftCode,
    Iban,
    Currencies,
  },
}
</script>
